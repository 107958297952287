/* Inicializa los elementos, sobrescribiendo los estilos de la pagina */
.container-btn-widget-customer-capture {
    all: initial;
    text-align: center;
}

.container-widget-content {
    padding: 1rem;
}

/* Modal */
#myModal-widget-customer-capture:not( .container-widget-customer-capture ) {
    display: none;
}
/* The Modal (background) */
#myModal-widget-customer-capture.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 999999;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content/Box */
#myModal-widget-customer-capture .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 15% auto;
    margin-top: 5%;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    border-radius: 1rem;
    /* width: 80%; */
    /* Could be more or less, depending on screen size */
    color: black;

    font-family: Verdana;

    animation-name: animatetop;
    animation-duration: 0.4s;
}

/* The Close Button */
#myModal-widget-customer-capture .close-modal-widget-customer-capture {
    /* color: #aaa; */
    color: inherit;
    /* float: right; */
    font-size: 28px;
    font-weight: bold;

    position: absolute;
    right: 1rem;
    top: .15rem;
}

#myModal-widget-customer-capture .close-modal-widget-customer-capture:hover,
#myModal-widget-customer-capture .close-modal-widget-customer-capture:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Add Animation */
@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

/* Fin Modal */

/* Botones */
.myButtons-widget-customer-capture {
    background-color: slategray;
    -webkit-border-radius: .75rem;
    -moz-border-radius: .75rem;
    border-radius: .75rem;
    border: 1px solid slategray;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Verdana;
    font-size: 1rem;
    padding: 1rem .8rem;
    text-decoration: none;
    text-shadow: 0px 1px 0px black;
    /* #2f6627 */
    white-space: normal;
    height: fit-content;
}

.myButtons-widget-customer-capture.inPage {
    /* background-color:slategray;
	-webkit-border-radius: .75rem;
	-moz-border-radius: .75rem;
	border-radius: .75rem;
	border:1px solid slategray;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Verdana;
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #2f6627; */

    padding: 1rem 1.4rem;

    margin-top: 2rem;
    margin-bottom: 2rem;

    height: fit-content;
    border: unset;
}

.myButtons-widget-customer-capture:hover {
    filter: brightness(0.95);
}

/* Fin Botones */

/* Formulario del Modal */
#myModal-widget-customer-capture .modal-content .container-form {
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 1rem;
}

#myModal-widget-customer-capture .title-header-form {
    font-weight: 500;
    border-bottom: 1px solid;
    margin-right: 1rem;
    margin-left: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    color: inherit;
}

#myModal-widget-customer-capture .form-group-modal {
    margin-bottom: 1rem;
    text-align: start;
}

#myModal-widget-customer-capture .form-group-modal label,
#myModal-widget-customer-capture .form-group-modal span {
    display: block;
    margin-bottom: .5rem;
}

/* #myModal-widget-customer-capture .form-group-modal label {
    margin-bottom: .5rem;
} */

#myModal-widget-customer-capture .form-group-modal span {
    margin-top: .5rem;
    color: red;
}

#myModal-widget-customer-capture .form-input-modal-widget::placeholder {
    color: #6c757d;
    opacity: 1;
}

#myModal-widget-customer-capture .form-input-modal-widget {
    display: block;
    width: 100%;
    width: -moz-available;
    height: calc(1.5em + .75rem + 2px);
    /* padding: .375rem .75rem; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

#myModal-widget-customer-capture .form-group-buttons-modal-widget {
    padding-top: .6rem;
    text-align: center;
}

#myModal-widget-customer-capture .form-group-buttons-modal-widget .hide-button-widget {
    display: none !important;
}

#myModal-widget-customer-capture .form-group-buttons-modal-widget button {
    margin: 1rem;
}

#myModal-widget-customer-capture div.field-phone {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
}

#myModal-widget-customer-capture div.field-phone > select {
    width: 30%;
    margin-right: 1rem;
}

#myModal-widget-customer-capture div.field-phone > input {
    width: 70%;
}


#myModal-widget-customer-capture .form-group-modal:has(input#form-accepted-terms-checkbox-modal-widget-customer-capture) {
    display: flex;
    flex-direction: row;
    padding: 0 .8rem;
}
#myModal-widget-customer-capture .form-group-modal:has(input#form-accepted-terms-checkbox-modal-widget-customer-capture) > input {
    align-self: flex-start;
    margin-top: .8rem;
    margin-right: 1rem;
}
#myModal-widget-customer-capture .form-group-modal:has(input#form-accepted-terms-checkbox-modal-widget-customer-capture) > label,
#myModal-widget-customer-capture .form-group-modal:has(input#form-accepted-terms-checkbox-modal-widget-customer-capture) > label > p {
    margin-top: 0;
    margin-bottom: 0;
}
#myModal-widget-customer-capture .form-group-modal:has(input#form-accepted-terms-checkbox-modal-widget-customer-capture) > label > p > a {
    text-decoration: none;
    color: inherit;
}

/* Ancho del modal */

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    #myModal-widget-customer-capture .modal-content {
        width: 85%;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    #myModal-widget-customer-capture .modal-content {
        width: 80%;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    #myModal-widget-customer-capture .modal-content {
        width: 60%;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    #myModal-widget-customer-capture .modal-content {
        width: 50%;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    #myModal-widget-customer-capture .modal-content {
        width: 40%;
    }
 } 

/* Fin Formulario del Modal */